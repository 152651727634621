<template>
  <page
    :title="title"
    :breadcrumbs="breadcrumbs"
    :apiStatusList="[fetchTestReportStatus]"
    minimal
  >
    <v-card class="mt-5">
      <v-row>
        <v-col class="ml-2 text-h6"> Status </v-col>
      </v-row>
      <v-row>
        <v-col class="pl-10 pa-8">
          <div class="body-1">Test Status: {{ testStatus }}</div>
        </v-col>
      </v-row>
      <v-row v-if="submitted">
        <v-col class="pl-10">
          <v-row>
            <v-col :cols="2"> Total Score </v-col>
            <v-col :cols="2">
              {{ testReportView && testReportView.getOverallScore() }}
            </v-col>
          </v-row>
          <v-row>
            <v-col :cols="2"> Started At </v-col>
            <v-col :cols="4">
              {{ startedAt }}
            </v-col>
          </v-row>
          <v-row>
            <v-col :cols="2"> Finished At </v-col>
            <v-col :cols="4">
              {{ endedAt }}
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="ml-2 text-h6"> Problem scores </v-col>
      </v-row>
      <v-row>
        <v-col class="pb-0">
          <v-card flat outlined>
            <v-data-table
              :hide-default-footer="true"
              :headers="headers"
              :items="problemScores"
              no-data-text="No submissions were made by the candidate"
            >
              <template v-slot:item="{ item }">
                <tr>
                  <td>
                    {{ item.getContentPreview().getTitle() }}
                  </td>
                  <td>
                    <router-link :to="submissionUrl(item)">
                      {{ item.getProblemScore() }}
                    </router-link>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-if="submissionHistory && submissionHistory.length != 0">
        <v-col class="ml-2 text-h6"> Submissions </v-col>
      </v-row>
      <v-row v-if="submissionHistory && submissionHistory.length != 0">
        <v-col>
          <v-data-table
            class="submission_table rounded-md"
            :headers="staticHeaders"
            :items="submissionHistory"
            no-data-text="No submissions were made by the candidate"
          >
            <template v-slot:item="{ item }">
              <tr>
                <td>
                  <router-link
                    class="problem_link"
                    :to="'/problems/' + item.getProblemPreview().getUrl()"
                    target="_blank"
                  >
                    {{ item.getProblemPreview().getTitle() }}
                  </router-link>
                </td>
                <td>{{ formatDate(item.getSubmittedOn()) }}</td>
                <td
                  class="text-capitalize"
                  v-bind:class="{
                    'green--text': isCorrectSubmission(item),
                    'red--text': isIncorrectSubmission(item),
                  }"
                >
                  {{ getStatus(item.getStatus()) }}
                </td>
                <td>{{ item.getTimeTakenInMs() }} ms</td>
                <td>{{ item.getMemoryTakenInKb() }} KiB</td>
                <td>
                  <router-link
                    outlined
                    class="view_details_btn"
                    height="25"
                    :to="'/submissions/' + item.getId()"
                    target="_blank"
                  >
                    View Details
                  </router-link>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card>
  </page>
</template>

<script>
import Page from '../components/Page'
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex'
import moment from 'moment'

export default {
  props: {
    url: {
      type: String,
      required: true,
    },
    userId: {
      type: String,
      required: true,
    },
  },
  data: function () {
    return {
      submitted: true,
      totalScore: 150,
      headers: [
        {
          text: 'Question',
          align: 'left',
          value: 'problem',
        },
        {
          text: 'Score',
          align: 'left',
          value: 'score',
        },
      ],
      footerOptions: {
        itemsPerPageOptions: [10, 25, 50],
      },
      staticHeaders: [
        {
          text: 'Problem Title',
          align: 'left',
          sortable: false,
        },
        {
          text: 'Submisison Date',
          align: 'left',
          sortable: false,
        },
        {
          text: 'Status',
          align: 'left',
          sortable: false,
        },
        {
          text: 'Time Limit',
          align: 'left',
          sortable: false,
        },
        {
          text: 'Memory Limit',
          align: 'left',
          sortable: false,
        },
        {
          text: '',
          align: 'left',
          sortable: false,
        },
      ],
    }
  },
  computed: {
    ...mapState('hiring', [
      'testReportView',
      'userPreview',
      'fetchTestReportStatus',
    ]),
    ...mapGetters('content', ['statusKeys']),
    ...mapGetters('judge', ['languageKeys', 'statusKeys']),
    title() {
      if (this.testReportView)
        return 'Test report - ' + this.testReportView.getUserName()
      return 'Test report'
    },
    breadcrumbs() {
      var crumbs = []
      crumbs.push({
        text: 'Hiring Dashboard',
        href: '/hiring/dashboard',
      })
      /*
			crumbs.push({
				text: this.contentView.getTitle(),
				href: "/hiring/tests/" + url,
			});
			*/
      if (this.testReportView) {
        crumbs.push({
          text: this.testReportView.getUserName(),
          href: '/hiring/tests/' + this.url + '/reports/' + this.userId,
          disabled: true,
        })
      }

      return crumbs
    },
    testStatus() {
      if (!this.testReportView) return 'Waiting'
      if (this.testReportView.getStartedAt() == 0) return 'Invited'
      if (this.testReportView.getEndedAt() == 0)
        return 'Waiting for candidate to finish'
      return 'Finished'
    },
    startedAt() {
      if (!this.testReportView) return 'Waiting'
      var startedAt = this.testReportView.getStartedAt()
      if (startedAt == 0) return '--'
      return moment.unix(startedAt).format('D MMM YYYY, ddd HH:mm:ss z')
    },
    endedAt() {
      if (!this.testReportView) return 'Waiting'
      var endedAt = this.testReportView.getEndedAt()
      if (endedAt == 0) return '--'
      return moment.unix(endedAt).format('D MMM YYYY, ddd HH:mm:ss z')
    },
    problemScores() {
      return (
        this.testReportView && this.testReportView.getProblemScoreViewList()
      )
    },
    submissionHistory() {
      return (
        this.testReportView && this.testReportView.getSubmissionPreviewList()
      )
    },
  },
  methods: {
    ...mapActions('hiring', ['fetchTestReport']),
    submissionUrl(item) {
      console.log('ps ', item.getSubmissionPreview())
      var link = '/submissions/' + item.getSubmissionPreview().getId()
      return link
    },
    formatDate(value) {
      if (value) {
        return new moment(new Date(value)).fromNow()
      }
    },

    isCorrectSubmission(item) {
      console.log('Submission is ../', item.toObject())
      return item.getStatus() === 13 ? true : false
    },

    isIncorrectSubmission(item) {
      return item.getStatus() >= 10 && item.getStatus() != 13 ? true : false
    },

    getStatus(status) {
      return this.statusKeys[status].split('_').join(' ').toLowerCase()
    },
  },
  mounted() {
    this.fetchTestReport({ url: this.url, userId: this.userId })
  },
  components: {
    Page,
  },
}
</script>
<style scoped>
.submission_table {
  border: 1px solid #cccccc;
}

@media only screen and (min-width: 1224px) {
  .submission_table {
    max-width: 75%;
  }
}

.submission_table tr:nth-of-type(odd) {
  background-color: #fbfbfb;
}

.submission_table tr td {
  border-bottom: none !important;
}

.view_details_btn {
  color: #33a79d;
  text-decoration: none;
}

.problem_link {
  text-decoration: none;
  color: black;
}
</style>
