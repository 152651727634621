var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('page', {
    attrs: {
      "title": _vm.title,
      "breadcrumbs": _vm.breadcrumbs,
      "apiStatusList": [_vm.fetchTestReportStatus],
      "minimal": ""
    }
  }, [_c('v-card', {
    staticClass: "mt-5"
  }, [_c('v-row', [_c('v-col', {
    staticClass: "ml-2 text-h6"
  }, [_vm._v(" Status ")])], 1), _c('v-row', [_c('v-col', {
    staticClass: "pl-10 pa-8"
  }, [_c('div', {
    staticClass: "body-1"
  }, [_vm._v("Test Status: " + _vm._s(_vm.testStatus))])])], 1), _vm.submitted ? _c('v-row', [_c('v-col', {
    staticClass: "pl-10"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": 2
    }
  }, [_vm._v(" Total Score ")]), _c('v-col', {
    attrs: {
      "cols": 2
    }
  }, [_vm._v(" " + _vm._s(_vm.testReportView && _vm.testReportView.getOverallScore()) + " ")])], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": 2
    }
  }, [_vm._v(" Started At ")]), _c('v-col', {
    attrs: {
      "cols": 4
    }
  }, [_vm._v(" " + _vm._s(_vm.startedAt) + " ")])], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": 2
    }
  }, [_vm._v(" Finished At ")]), _c('v-col', {
    attrs: {
      "cols": 4
    }
  }, [_vm._v(" " + _vm._s(_vm.endedAt) + " ")])], 1)], 1)], 1) : _vm._e(), _c('v-row', [_c('v-col', {
    staticClass: "ml-2 text-h6"
  }, [_vm._v(" Problem scores ")])], 1), _c('v-row', [_c('v-col', {
    staticClass: "pb-0"
  }, [_c('v-card', {
    attrs: {
      "flat": "",
      "outlined": ""
    }
  }, [_c('v-data-table', {
    attrs: {
      "hide-default-footer": true,
      "headers": _vm.headers,
      "items": _vm.problemScores,
      "no-data-text": "No submissions were made by the candidate"
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('tr', [_c('td', [_vm._v(" " + _vm._s(item.getContentPreview().getTitle()) + " ")]), _c('td', [_c('router-link', {
          attrs: {
            "to": _vm.submissionUrl(item)
          }
        }, [_vm._v(" " + _vm._s(item.getProblemScore()) + " ")])], 1)])];
      }
    }])
  })], 1)], 1)], 1), _vm.submissionHistory && _vm.submissionHistory.length != 0 ? _c('v-row', [_c('v-col', {
    staticClass: "ml-2 text-h6"
  }, [_vm._v(" Submissions ")])], 1) : _vm._e(), _vm.submissionHistory && _vm.submissionHistory.length != 0 ? _c('v-row', [_c('v-col', [_c('v-data-table', {
    staticClass: "submission_table rounded-md",
    attrs: {
      "headers": _vm.staticHeaders,
      "items": _vm.submissionHistory,
      "no-data-text": "No submissions were made by the candidate"
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_c('tr', [_c('td', [_c('router-link', {
          staticClass: "problem_link",
          attrs: {
            "to": '/problems/' + item.getProblemPreview().getUrl(),
            "target": "_blank"
          }
        }, [_vm._v(" " + _vm._s(item.getProblemPreview().getTitle()) + " ")])], 1), _c('td', [_vm._v(_vm._s(_vm.formatDate(item.getSubmittedOn())))]), _c('td', {
          staticClass: "text-capitalize",
          class: {
            'green--text': _vm.isCorrectSubmission(item),
            'red--text': _vm.isIncorrectSubmission(item)
          }
        }, [_vm._v(" " + _vm._s(_vm.getStatus(item.getStatus())) + " ")]), _c('td', [_vm._v(_vm._s(item.getTimeTakenInMs()) + " ms")]), _c('td', [_vm._v(_vm._s(item.getMemoryTakenInKb()) + " KiB")]), _c('td', [_c('router-link', {
          staticClass: "view_details_btn",
          attrs: {
            "outlined": "",
            "height": "25",
            "to": '/submissions/' + item.getId(),
            "target": "_blank"
          }
        }, [_vm._v(" View Details ")])], 1)])];
      }
    }], null, false, 1579201843)
  })], 1)], 1) : _vm._e()], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }